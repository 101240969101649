import "./contact-form.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsForm extends PropsWithChildren<any> {
  handleSubmit: any;
  submitting: boolean;
  success: boolean;
  fail: boolean;
  from?: string;
}

export const Contact = (props: PropsForm) => (
  <form name="contacto" method="post" onSubmit={props.handleSubmit} className="m-contact-form">
    <input type="text" name="from" value={`${props.from ? props.from : "Página de inicio"}`} readOnly hidden />
    <label hidden>Nombre </label>
    <input className={"a-input"} type="text" name="name" placeholder="Nombre" required />
    <label hidden>Email </label>
    <input className={"a-input"} type="email" name="email" placeholder="email@xxx.com" required />
    <label hidden>Teléfono </label>
    <input className={"a-input"} type="tel" name="phone" placeholder="teléfono" required />
    <textarea className={"a-input"} name="message" placeholder={"mensaje"} />
    <div className={"a-private"}>
      <label htmlFor="policy">
        {" "}
        Acepto la{" "}
        <a
          href="/legal/politica-de-privacidad/"
          target="_blank"
          rel="nofollow noopener noreferrer"
          className={"a-policy"}
        >
          política de privacidad
        </a>
      </label>
      <input type="checkbox" name="policy" id="policy" value="Sí" required />
    </div>

    <div className="button">
      {!props.success && (
        <button type="submit" className={`hey ${props.submitting ? "button--white" : ""} `}>
          Enviar {props.submitting && <span className="a-spinner" />}
        </button>
      )}
    </div>
    {props.fail && (
      <span className="a-fail">
        Ha ocurrido un <b>error</b>, puedes solicitar la información mandando un correo a{" "}
        <a href="mailto:" target={"_blank"} rel={"noopener noreferrer nofollow"}>
          correo@xxx.com
        </a>{" "}
      </span>
    )}
    {props.success && <span className="a-success">¡Gracias! Mensaje enviado</span>}
  </form>
);
