import "./hero.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface PropsHero extends PropsWithChildren<any> {
  title: string;
  background?: any;
}

const Hero: React.FC<PropsHero> = (props: PropsHero) => {
  return (
    <section className={"o-hero container"}>
      <h1>{props.title}</h1>
    </section>
  );
};

export default Hero;
