import React from "react";

import Layout from "../components/common/layout/layout";
import { Contact } from "../components/Forms/Contact/Contact";
import withForm from "../components/Forms/withForm";
import Hero from "../components/Hero/Hero";
import Map from "../components/Map/Map";
import SEO from "../components/SEO/SEO";
import PcisBanners from "../PCIS/components/PCIsBanner/PcisBanners";

const Page404 = () => {
  const ContactWithForm = withForm(Contact, "Profesorado");
  return (
    <>
      <SEO title={"Ups"} description={"Error 404"} robots={"noindex, nofollow"} />
      <Layout>
        <Hero title={"Error 404"} />
        <main>
          <section className={"container"}>
            <div style={{ maxWidth: "800px" }}>
              <p className={"h2"}>No hemos enctontrado lo que andabas buscando</p>
              <p>Pero aquí tienes nuestros PCIs y un formulario de contacto por si necesitas algo más de información</p>
            </div>
          </section>

          <PcisBanners />
          <Map>
            <h2> ¿Necesitas ponerte en contacto con el departamento PCI IES Ramón y Cajal?</h2>
            <p>Si tienes alguna duda puedes rellenar el siguiente formulario y te la resolveremos sin compromiso.</p>
            <ContactWithForm />
          </Map>
        </main>
      </Layout>
    </>
  );
};

export default Page404;
